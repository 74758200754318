import { StringConstants } from "global/constants/StringConstants";

class UrlConstants extends StringConstants {
  PROD = true;

  url_prod = "https://api.vedi.love/v4";
  url_dev = "https://uat-api.vedi.love";

  url = this.PROD ? this.url_prod : this.url_dev;

  //use common name of APIs
  public = "public";
  person = "person";

  // public APIs
  REFRESHTOKEN = "";
  webhook_URL = "";
  CONTACT_US = `${this.url}/${this.public}/contact-us`;
  footballPrediction = `${this.url}/${this.person}/prediction`;
  professionPrediction = `${this.url}/${this.person}/profession-prediction`;
}

let urls = new UrlConstants();
export default urls;
