import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import {
  Box,
  Container,
  Grid,
  keyframes,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "global/components/CustomButton/CustomButton";
import viewpaths from "global/constants/ViewPathConstants";
import { useTranslation } from "react-i18next";
import AnimatedSection from "screens/Shared/AnimationSection";
import history from "utils/history";
import { animationStyle, LightTooltip, theme } from "utils/styles";
import filledStar from "../../assets/icons/filledStar.svg";
import infoIcon from "../../assets/icons/infoIcon.svg";
import outlinedStar from "../../assets/icons/outlinedStar.svg";
import healthCalculator from "../../assets/images/healthCalculator.webp";
import careerCalculator from "../../assets/images/img2.jpg";
import servicesImage from "../../assets/images/servicesImage.webp";
import sportsCalculator from "../../assets/images/sportsCalculator.jpg";
import calculatorsStyle from "./Calculators.styles";

const animation: any = makeStyles(animationStyle);

const rotateLeft = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`;

const rotateRight = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Calculators = () => {
  const classes = calculatorsStyle;
  const animationClasses = animation();
  const { t } = useTranslation();

  const getHeader = () => (
    <Box
      sx={{
        background: "#2f3749",
      }}
    >
      <Box sx={classes.backImg}>
        <Box sx={classes.innerBackground}>
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
            maxHeight={"62vh"}
            spacing={2}
            className={animationClasses.fadeInDown}
          >
            <Typography sx={classes.headerText}>{t("calculators")}</Typography>
            <Typography sx={{ ...classes.subHeaderText, textAlign: "center" }}>
              {t("calculatorHeader")}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );

  const getSportsCalculator = () => {
    return (
      <Box sx={classes.darkBackground}>
        <Container maxWidth="xl">
          <Grid container spacing={5} alignItems={"center"}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Stack direction={"column"} spacing={2}>
                <Typography
                  sx={classes.gradientText}
                  className={animationClasses.fadeInLeft}
                >
                  {t("sports")}
                </Typography>
                <Typography
                  sx={classes.calculatorDescText}
                  className={animationClasses.fadeInLeft}
                >
                  {t("sportsDesc")}
                </Typography>
                <AnimatedSection animationType="fadeInLeft">
                  <Typography sx={classes.calculatorAdvText}>
                    {t("advantages")}:
                  </Typography>
                </AnimatedSection>
                <Stack direction={"column"} spacing={1.5} pb={2}>
                  {Array.from({ length: 10 }).map((adv: any, index: number) => {
                    return (
                      <AnimatedSection animationType="fadeInLeft">
                        <LightTooltip
                          title={t(`sportsAdvDesc${index + 1}`)}
                          placement="top"
                          arrow
                          sx={{
                            "& .MuiTooltip-arrow": {
                              color: "#1b202a",
                            },
                          }}
                        >
                          <Stack
                            direction={"row"}
                            spacing={1}
                            sx={{ cursor: "pointer", width: "fit-content" }}
                            key={index}
                          >
                            <Box
                              sx={{
                                background:
                                  "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
                                borderRadius: "50%",
                                display: "flex",
                                height: "fit-content",
                              }}
                            >
                              <Box
                                component="img"
                                src={infoIcon}
                                alt={"info icon"}
                                sx={{ height: "26px" }}
                              />
                            </Box>
                            <Typography
                              sx={{
                                ...classes.subHeaderText,
                                color: "#ffffff",
                              }}
                            >
                              {t(`sportsAdvHeader${index + 1}`)}
                            </Typography>
                          </Stack>
                        </LightTooltip>
                      </AnimatedSection>
                    );
                  })}
                </Stack>
                <AnimatedSection animationType="fadeInLeft">
                  <CustomButton
                    label={t("tryNow")}
                    buttonType="gradientBtn"
                    onClick={() =>
                      history.push(viewpaths.footballPredictionViewPath)
                    }
                    customClasses={classes.tryNowBtn}
                  />
                </AnimatedSection>
              </Stack>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box
                sx={{ display: "flex", position: "relative" }}
                className={animationClasses.zoomIn}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "0px",
                    position: "relative",
                    left: "0px",
                    bottom: "20px",
                    alignItems: "end",
                  }}
                >
                  <Box
                    component="img"
                    src={filledStar}
                    alt={"star icon"}
                    sx={{
                      height: "30px",
                      animation: `${rotateRight} 10s linear infinite`,
                    }}
                  />
                  <Box
                    component="img"
                    src={outlinedStar}
                    alt={"star icon"}
                    sx={{
                      height: "25px",
                      position: "relative",
                      top: "30px",
                      animation: `${rotateLeft} 10s linear infinite`,
                    }}
                  />
                </Box>
                <Box
                  component="img"
                  src={sportsCalculator}
                  alt={"Sports Calculator Image"}
                  sx={{
                    height: "fit-content",
                    width: "100%",
                    // borderRadius: "6px",
                    borderTopRightRadius: "50%",
                    borderBottomLeftRadius: "50%",
                    [theme.breakpoints.down("md")]: {
                      maxHeight: "400px",
                      width: "auto",
                    },
                    [theme.breakpoints.down("sm")]: {
                      width: "100%",
                    },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    width: "0px",
                    position: "relative",
                    right: "70px",
                    top: "20px",
                  }}
                >
                  <Box
                    component="img"
                    src={filledStar}
                    alt={"star icon"}
                    sx={{
                      height: "30px",
                      animation: `${rotateRight} 10s linear infinite`,
                    }}
                  />
                  <Box
                    component="img"
                    src={outlinedStar}
                    alt={"star icon"}
                    sx={{
                      height: "25px",
                      position: "relative",
                      top: "30px",
                      animation: `${rotateLeft} 10s linear infinite`,
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  const getCareerCalculator = () => {
    return (
      <Box sx={classes.darkBackground}>
        <Container maxWidth="xl">
          <Grid container spacing={5} alignItems={"center"}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <AnimatedSection animationType="zoomIn">
                <Box sx={{ display: "flex", position: "relative" }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "0px",
                      position: "relative",
                      top: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      src={filledStar}
                      alt={"star icon"}
                      sx={{
                        height: "30px",
                        animation: `${rotateLeft} 10s linear infinite`,
                      }}
                    />
                    <Box
                      component="img"
                      src={outlinedStar}
                      alt={"star icon"}
                      sx={{
                        height: "25px",
                        position: "relative",
                        top: "30px",
                        animation: `${rotateRight} 10s linear infinite`,
                      }}
                    />
                  </Box>
                  <Box
                    component="img"
                    src={careerCalculator}
                    alt={"Career Calculator Image"}
                    sx={{
                      height: "fit-content",
                      width: "100%",
                      // borderRadius: "6px",
                      borderTopLeftRadius: "50%",
                      borderBottomRightRadius: "50%",
                      [theme.breakpoints.down("md")]: {
                        maxHeight: "400px",
                        width: "auto",
                      },
                      [theme.breakpoints.down("sm")]: {
                        width: "100%",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      width: "0px",
                      position: "relative",
                      right: "50px",
                      bottom: "20px",
                      alignItems: "end",
                    }}
                  >
                    <Box
                      component="img"
                      src={filledStar}
                      alt={"star icon"}
                      sx={{
                        height: "30px",
                        animation: `${rotateLeft} 10s linear infinite`,
                      }}
                    />
                    <Box
                      component="img"
                      src={outlinedStar}
                      alt={"star icon"}
                      sx={{
                        height: "25px",
                        position: "relative",
                        top: "30px",
                        animation: `${rotateRight} 10s linear infinite`,
                      }}
                    />
                  </Box>
                </Box>
              </AnimatedSection>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Stack direction={"column"} spacing={2}>
                <AnimatedSection animationType="fadeInLeft">
                  <Typography sx={classes.gradientText}>
                    {t("career")}
                  </Typography>
                </AnimatedSection>
                <AnimatedSection animationType="fadeInLeft">
                  <Typography sx={classes.calculatorDescText}>
                    {t("careerDesc")}
                  </Typography>
                </AnimatedSection>
                <AnimatedSection animationType="fadeInLeft">
                  <Typography sx={classes.calculatorAdvText}>
                    {t("advantages")}:
                  </Typography>
                </AnimatedSection>
                <Stack direction={"column"} spacing={1.5}>
                  {Array.from({ length: 10 }).map((adv: any, index: number) => {
                    return (
                      <AnimatedSection animationType="fadeInLeft">
                        <LightTooltip
                          title={t(`careerAdvDesc${index + 1}`)}
                          placement="top"
                          arrow
                          sx={{
                            "& .MuiTooltip-arrow": {
                              color: "#1b202a",
                            },
                          }}
                        >
                          <Stack
                            direction={"row"}
                            spacing={1}
                            sx={{ cursor: "pointer", width: "fit-content" }}
                            key={index}
                          >
                            <Box
                              sx={{
                                background:
                                  "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
                                borderRadius: "50%",
                                display: "flex",
                                height: "fit-content",
                              }}
                            >
                              <Box
                                component="img"
                                src={infoIcon}
                                alt={"info icon"}
                                sx={{ height: "26px" }}
                              />
                            </Box>
                            <Typography
                              sx={{
                                ...classes.subHeaderText,
                                color: "#ffffff",
                              }}
                            >
                              {t(`careerAdvHeader${index + 1}`)}
                            </Typography>
                          </Stack>
                        </LightTooltip>
                      </AnimatedSection>
                    );
                  })}
                </Stack>
                <AnimatedSection animationType="fadeInLeft">
                  <CustomButton
                    label={t("tryNow")}
                    buttonType="gradientBtn"
                    onClick={() =>
                      history.push(viewpaths.professionPredictionViewPath)
                    }
                    customClasses={classes.tryNowBtn}
                  />
                </AnimatedSection>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  const getHealthCalculator = () => {
    return (
      <Box sx={classes.darkBackground}>
        <Container maxWidth="xl">
          <Grid container spacing={5} alignItems={"center"}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Stack direction={"column"} spacing={2}>
                <AnimatedSection animationType="fadeInLeft">
                  <Typography sx={classes.gradientText}>
                    {t("health")}
                  </Typography>
                </AnimatedSection>
                <AnimatedSection animationType="fadeInLeft">
                  <Typography sx={classes.calculatorDescText}>
                    {t("healthDesc")}
                  </Typography>
                </AnimatedSection>
                <AnimatedSection animationType="fadeInLeft">
                  <Typography sx={classes.calculatorAdvText}>
                    {t("advantages")}:
                  </Typography>
                </AnimatedSection>
                <Stack direction={"column"} spacing={1.5}>
                  {Array.from({ length: 10 }).map((adv: any, index: number) => {
                    return (
                      <AnimatedSection animationType="fadeInLeft">
                        <LightTooltip
                          title={t(`healthAdvDesc${index + 1}`)}
                          placement="top"
                          arrow
                          sx={{
                            "& .MuiTooltip-arrow": {
                              color: "#1b202a",
                            },
                          }}
                        >
                          <Stack
                            direction={"row"}
                            spacing={1}
                            sx={{ cursor: "pointer", width: "fit-content" }}
                            key={index}
                          >
                            <Box
                              sx={{
                                background:
                                  "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
                                borderRadius: "50%",
                                display: "flex",
                                height: "fit-content",
                              }}
                            >
                              <Box
                                component="img"
                                src={infoIcon}
                                alt={"info icon"}
                                sx={{ height: "26px" }}
                              />
                            </Box>
                            <Typography
                              sx={{
                                ...classes.subHeaderText,
                                color: "#ffffff",
                              }}
                            >
                              {t(`healthAdvHeader${index + 1}`)}
                            </Typography>
                          </Stack>
                        </LightTooltip>
                      </AnimatedSection>
                    );
                  })}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <AnimatedSection animationType="zoomIn">
                <Box sx={{ display: "flex", position: "relative" }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "0px",
                      position: "relative",
                      left: "0px",
                      bottom: "20px",
                      alignItems: "end",
                    }}
                  >
                    <Box
                      component="img"
                      src={filledStar}
                      alt={"star icon"}
                      sx={{
                        height: "30px",
                        animation: `${rotateRight} 10s linear infinite`,
                      }}
                    />
                    <Box
                      component="img"
                      src={outlinedStar}
                      alt={"star icon"}
                      sx={{
                        height: "25px",
                        position: "relative",
                        top: "30px",
                        animation: `${rotateLeft} 10s linear infinite`,
                      }}
                    />
                  </Box>
                  <Box
                    component="img"
                    src={healthCalculator}
                    alt={"Health Calculator Image"}
                    sx={{
                      height: "fit-content",
                      width: "100%",
                      // borderRadius: "6px",
                      borderTopRightRadius: "50%",
                      borderBottomLeftRadius: "50%",
                      [theme.breakpoints.down("md")]: {
                        maxHeight: "400px",
                        width: "auto",
                      },
                      [theme.breakpoints.down("sm")]: {
                        width: "100%",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      width: "0px",
                      position: "relative",
                      right: "70px",
                      top: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      src={filledStar}
                      alt={"star icon"}
                      sx={{
                        height: "30px",
                        animation: `${rotateRight} 10s linear infinite`,
                      }}
                    />
                    <Box
                      component="img"
                      src={outlinedStar}
                      alt={"star icon"}
                      sx={{
                        height: "25px",
                        position: "relative",
                        top: "30px",
                        animation: `${rotateLeft} 10s linear infinite`,
                      }}
                    />
                  </Box>
                </Box>
              </AnimatedSection>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  const getContactUs = () => {
    return (
      <Box sx={classes.darkBackground}>
        <Container maxWidth="md">
          <Stack
            direction={{ md: "row", xs: "column" }}
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              component="img"
              src={servicesImage}
              alt={"icon"}
              sx={{
                animation: `${rotateLeft} 10s linear infinite`,
              }}
            />
            <Stack direction={"column"} spacing={3}>
              <Typography sx={classes.contentHeaderText}>
                {t("needInfo")}
              </Typography>
              <Typography
                sx={{
                  ...classes.calculatorAdvText,
                  textAlign: "center",
                }}
              >
                {t("needInfoDesc")}
              </Typography>
            </Stack>

            <CustomButton
              label={t("contactUs")}
              buttonType="gradientBtn"
              onClick={() => history.push(viewpaths.contactViewPath)}
              customClasses={classes.contactBtn}
              endIcon={<EastOutlinedIcon />}
            />
          </Stack>
        </Container>
      </Box>
    );
  };

  const getCalculators = () => (
    <>
      {getHeader()}
      {getSportsCalculator()}
      {getCareerCalculator()}
      {getHealthCalculator()}
      {getContactUs()}
    </>
  );

  return getCalculators();
};

export default Calculators;
