import {
  getRelativeFontSize,
  mediumFont,
  primaryColorWhite,
  regularFont,
  theme,
} from "utils/styles";
import contactUsBackImage from "../../assets/images/contactUsBackImage.svg";

const professionStyles = {
  backImg: {
    minHeight: "50vh",
    height: "100%",
    background: `url(${contactUsBackImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  darkBackground: {
    background: "#2f3749",
    position: "relative",
    boxShadow: "inset 0px 12px 4px -11px #474a51",
  },
  innerBackground: {
    backgroundColor: "rgba(51,59,79,0.98)",
    color: "inherit",
    position: "absolute",
    width: "100%",
    height: "100% !important",
    left: 0,
    top: 0,
  },
  headerText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(40),
    color: primaryColorWhite,
    lineHeight: "5.5rem",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(30),
      lineHeight: "5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(20),
      lineHeight: "4.5rem",
    },
  },
  subHeaderText: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    color: "#c7c8c9",
    linHeight: "19px",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(3),
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  gradientText: {
    margin: "0 auto",
    background:
      "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    ...mediumFont,
    fontSize: getRelativeFontSize(12),
    width: "fit-content",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(10),
    },
  },
  contactBtn: {
    border: "none",
    minWidth: "-webkit-fill-available",
    height: "50px !important",
  },
  noteText: {
    ...regularFont,
    fontSize: getRelativeFontSize(-1),
    color: "rgba(255,255,255,.5)",
  },
  nameField: {
    color: "rgba(255,255,255,.5)",
    ...regularFont,
    fontSize: getRelativeFontSize(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  predicationText: {
    ...regularFont,
    fontSize: getRelativeFontSize(9),
    color: primaryColorWhite,
    textAlign: "center",
    width: "fit-content",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(5),
      width: "100%",
    },
  },
} as const;

export default professionStyles;
