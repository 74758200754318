import { Box } from "@mui/material";
import withClearCache from "ClearCache";
import viewpaths from "global/constants/ViewPathConstants";
import "index.css";
import DrawerLayout from "layouts/DrawerLayout/DrawerLayout";
import { Suspense } from "react";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import About from "screens/About/About";
import Calculators from "screens/Calculators/Calculators";
import Contact from "screens/Contact/Contact";
import Football from "screens/Football/Football";
import Home from "screens/Home/Home";
import PageNotFound from "screens/PageNotFound/PageNotFound";
import PrivacyPolicy from "screens/PrivacyPolicy/PrivacyPolicy";
import Profession from "screens/Profession/Profession";
import Resources from "screens/Resources/Resources";
import history from "utils/history";
import Notifications from "utils/notifications";
import ScrollToTop from "utils/ScrollToTop";
import "./i18n";

const App = () => {
  return <ClearCacheComponent />;
};

const MainApp = () => {
  return (
    <Box>
      <Suspense fallback={null}>
        <BrowserRouter
          basename="/index.html"
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <Router history={history}>
            <ScrollToTop />
            <Switch>
              <DrawerLayout>
                <Route
                  exact
                  path={viewpaths.landingViewPath}
                  component={Home}
                />
                <Route exact path={viewpaths.aboutViewPath} component={About} />
                <Route
                  exact
                  path={viewpaths.calculatorsViewPath}
                  component={Calculators}
                />
                <Route
                  exact
                  path={viewpaths.resourcesViewPath}
                  component={Resources}
                />
                <Route
                  exact
                  path={viewpaths.contactViewPath}
                  component={Contact}
                />
                <Route
                  exact
                  path={viewpaths.privacyPolicyViewPath}
                  component={PrivacyPolicy}
                />
                <Route
                  exact
                  path={viewpaths.footballPredictionViewPath}
                  component={Football}
                />
                <Route
                  exact
                  path={viewpaths.professionPredictionViewPath}
                  component={Profession}
                />
              </DrawerLayout>
              <Route path={""} component={PageNotFound} />
            </Switch>
            <Notifications />
          </Router>
        </BrowserRouter>
      </Suspense>
    </Box>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

export default App;
