export class ViewPathConstants {
  // Root
  landingViewPath = "/";

  // Public Routes
  contactViewPath = "/contact";
  aboutViewPath = "/about";
  calculatorsViewPath = "/calculators";
  resourcesViewPath = "/resources";
  privacyPolicyViewPath = "/privacy-policy";
  footballPredictionViewPath = "/calculators/football-position-prediction";
  professionPredictionViewPath = "/calculators/profession-prediction";
  pageNotFoundViewPath = "/page-not-found";

  // Authenticated Routes
  dashboardViewPath = "/dashboard";
  settingsViewPath = "/settings";

  //holiday
  holidayCalenderViewPath = "/holiday-calender";
  holidayAddViewPath = `${this.holidayCalenderViewPath}/add`;
  holidayEditViewPath = `${this.holidayCalenderViewPath}/edit`;
}

const viewpaths = new ViewPathConstants();
export default viewpaths;
