import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "global/components/CustomButton/CustomButton";
import viewpaths from "global/constants/ViewPathConstants";
import { capitalizeWords } from "helpers/methods";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import history from "utils/history";
import {
  animationStyle,
  getRelativeFontSize,
  primaryColorWhite,
  regularFont,
} from "utils/styles";
import logo from "../../../assets/icons/logo.svg";
import drawerLayoutStyle from "../Drawer.styles";

const animation: any = makeStyles(animationStyle);

const TopNavSection = () => {
  const classes = drawerLayoutStyle;
  const currentPage = window.location.pathname.split("/")[1];
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const animationClasses = animation();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const navMenu = [
    {
      label: `${t("home")}`,
      url: viewpaths.landingViewPath,
    },
    {
      label: `${t("about")}`,
      url: viewpaths.aboutViewPath,
    },
    {
      label: `${t("calculators")}`,
      url: viewpaths.calculatorsViewPath,
    },
    {
      label: `${t("resources")}`,
      url: viewpaths.resourcesViewPath,
    },
    {
      label: `${t("contact")}`,
      url: viewpaths.contactViewPath,
    },
  ];

  const languages = [
    {
      label: "Polish",
      value: "pl",
      displayValue: "Pl",
    },
    {
      label: "English",
      value: "en",
      displayValue: "En",
    },
    {
      label: "French",
      value: "fr",
      displayValue: "Fr",
    },
    {
      label: "German",
      value: "de",
      displayValue: "De",
    },
    {
      label: "Spanish",
      value: "es",
      displayValue: "Es",
    },
    {
      label: "Hindi",
      value: "hi",
      displayValue: "Hi",
    },
    {
      label: "Chinese",
      value: "zh",
      displayValue: "Zh",
    },
  ];

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const getMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: classes.menuWrapper,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {languages.map((lang: any, index: number) => {
          return (
            <MenuItem
              onClick={() => handleLanguageChange(lang.value)}
              key={index}
              sx={{
                "&:hover": {
                  background: "#4e5663fc",
                },
              }}
            >
              <Typography sx={classes.menuItemText}>{lang.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={2}
      width={"100%"}
      justifyContent={"space-between"}
    >
      <Stack direction="row" spacing={6}>
        <Box
          className={animationClasses.fadeInUp}
          onClick={() => history.push(viewpaths.landingViewPath)}
        >
          <img src={logo} alt="logo" height={"45px"} />
        </Box>
        {mdUp && (
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            {navMenu.map((menuItem: any, index: number) => {
              return (
                <Box className={animationClasses.fadeInUp}>
                  <CustomButton
                    label={menuItem.label}
                    buttonType="transparentBtn"
                    onClick={() => history.push(menuItem.url)}
                  />
                  {`/${currentPage}` === menuItem.url && (
                    <Divider
                      sx={{
                        width: "50%",
                        border: "1px solid #ffffff",
                        margin: "0 auto",
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Stack>
        )}
      </Stack>
      <Box className={animationClasses.fadeInUp}>
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Typography
            sx={{
              ...regularFont,
              fontSize: getRelativeFontSize(2),
              color: primaryColorWhite,
              cursor: "pointer",
            }}
          >
            {capitalizeWords(i18n.language)}
          </Typography>
          <KeyboardArrowDownIcon
            htmlColor={primaryColorWhite}
            sx={{ fontSize: "12px" }}
          />
        </IconButton>
        {getMenu()}
      </Box>
    </Stack>
  );
};

export default TopNavSection;
